import React from 'react';

import SEO from '../components/SEO';
import Nav from '../components/nav';
import Hero from '../components/Hero-small';
import FigmaPlugin from '../components/FigmaPlugin';
import Footer from '../components/Footer';

class Index extends React.Component {
  render() {
    return (
      <>
        <SEO title="Figma Plugin" keywords={['']} pathname={this.props.location.pathname} />
        <Nav />
        <Hero />
        <FigmaPlugin />
        <Footer />
      </>
    );
  }
}

export default Index;
