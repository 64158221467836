import React, { useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { Heart } from 'react-bytesize-icons';
import { Square, Image, Type, Droplet } from 'react-feather';
import Img from 'gatsby-image';

const styles = require('./FigmaPlugin-styles.module.css');

const Columns = () => {
  return (
    <StaticQuery
      query={query}
      render={data => (
        <section className={styles.columns}>
          <h1>
            <img className={styles.icon} src={data.icon.publicURL} alt="Superposition" />
            <Heart width={48} height={48} /> <img className={styles.icon} src={data.figma.publicURL} alt="Figma" />
          </h1>
          <h2>Superposition plugin for Figma</h2>

          <p>
            Use the design system you already have. <br />
            Extract design tokens from websites and use them directly in Figma.
          </p>
          <img src={data.FigmaScreenshot.publicURL} alt="Figma plugin with Superposition" />

          <p>
            Available for free on the Figma community: <br />
            <a className={styles.button} href="https://www.figma.com/c/plugin/759050501649392825/Superposition">
              Get Superposition for Figma
            </a>
          </p>

          <div className={styles.row}>
            <div className={styles.item}>
              <Droplet width={48} height={48} />
              <h3>Colors</h3>
              <p>Add colors to local styles or use them as fills.</p>
            </div>

            <div className={styles.item}>
              <Type width={48} height={48} />
              <h3>Typography</h3>
              <p>Transform typography into what's being used on the site.</p>
            </div>
            <div className={styles.item}>
              <Square width={48} height={48} />
              <h3>Border radius</h3>
              <p>Apply radius to rectangles and vectors.</p>
            </div>
            <div className={styles.item}>
              <Heart width={48} height={48} />
              <h3>Shadows</h3>
              <p>Use the same shadows as your site, even layered ones.</p>
            </div>
            <div className={styles.item}>
              <Image width={48} height={48} />
              <h3>Images</h3>
              <p>Add images to your canvas or use them as background.</p>
            </div>
          </div>


        </section>
      )}
    />
  );
};

export const query = graphql`
  query {
    FigmaScreenshot: file(relativePath: { eq: "images/figmascreenshot.png" }) {
      publicURL
    }
    icon: file(relativePath: { eq: "images/icon.png" }) {
      publicURL
    }
    figma: file(relativePath: { eq: "images/figma.svg" }) {
      publicURL
    }
  }
`;

export default Columns;
